import { defineStore } from 'pinia'
import axios from '../services/axiosService'
import { useVoicemailStore } from './voicemail'
import { usePhoneStore } from './phone'
import { assertIsPhoneNumberAuthorized, assertIsPhoneNumberValid } from '@/helpers/phoneNumber'
import { fetchNotes } from '@/services/fetchNotes'
import { fetchCallLogs } from '@/services/fetchCallLogs'
import { fetchTasks } from '@/services/fetchTasks'
import { saveNewNote } from '@/services/saveNewNote'
import { saveNewTask } from '@/services/saveNewTask'
import { updateField } from '@/services/updateField'
import { updateNote } from '@/services/updateNote'
import { updateLog } from '@/services/updateLog'
import { updateCallDisposition } from '@/services/updateCallDisposition'
import { updateTask } from '@/services/updateTask'
import { fetchActivities } from '@/services/fetchActivities'
import { updatePhoneNumberField } from '@/services/updatePhoneNumberField'

export const useSessionStore = defineStore('session', {
  state: () => ({
    currentSessionId: null,
    error: null,
    fromPhoneNumber: { phoneNumber: '+33612345678' },
    fromPhoneNumberList: [],
    callInParallel: 1,
    sessionName: '',
    sessions: {},
    selectedRowId: null,
    sessionList: [],
    errorMessage: '',
    completeTaskWhenDialed: false,
    conversationThreshold: 30,
    tempSessionName: '',
    phoneNumberList: [],
    phoneNumberFieldSelected: null
  }),
  getters: {
    getFromNumber: (state) => state.fromPhoneNumber.phoneNumber,
    getMeetingBooked: (state) => (sessionId, contactId) =>
      !!state.sessions[sessionId]?.prospects.find((item) => item.id === contactId)?.meetingBooked
  },
  actions: {
    async updateConversationThreshold(conversationThreshold) {
      const oldValue = this.conversationThreshold
      try {
        this.conversationThreshold = conversationThreshold

        await axios.put('/api/user/settings', {
          conversationThreshold
        })

        console.log(`Settings conversation threshold updated successfully`)
      } catch (error) {
        console.error('Error while updating settings', error)
        this.errorMessage = 'Could not update settings'
        this.conversationThreshold = oldValue
      }
    },
    async updateCompleteTaskWhenDialedSetting(value) {
      try {
        this.completeTaskWhenDialed = value

        await axios.put('/api/user/settings', {
          completeTaskWhenDialed: value
        })

        console.log(`Setting completeTaskWhenDialed updated successfully`)
      } catch (error) {
        console.error('Error while updating setting', error)
        this.errorMessage = 'Could not update setting'
        this.completeTaskWhenDialed = !value
      }
    },
    async updatePhoneNumberFieldSetting(value, integrationName) {
      const oldValue = this.phoneNumberFieldSelected
      try {
        this.phoneNumberFieldSelected = value

        await axios.put('/api/user/settings/phone-number-field', {
          phoneNumberField: value,
          integrationName
        })

        console.log(`Setting phoneNumberField updated successfully`)
      } catch (error) {
        console.error('Error while updating setting', error)
        this.errorMessage = 'Could not update setting'
        this.phoneNumberFieldSelected = oldValue
      }
    },
    setUserSetting(userSetting) {
      this.completeTaskWhenDialed = userSetting.completeTaskWhenDialed
      this.conversationThreshold = userSetting.conversationThreshold
    },
    setSessions(sessions) {
      this.sessionList = sessions
    },
    async fetchPhoneNumbers() {
      try {
        const { data } = await axios.get(`/api/user/phone-numbers`)
        this.fromPhoneNumberList = data.phoneNumbers
        if (!this.currentSessionId) {
          this.fromPhoneNumber = this.fromPhoneNumberList[0]
        }
      } catch (error) {
        console.error('Error fetching phone numbers:', error)
      }
    },
    reset() {
      this.$reset()
    },
    checkActiveSession(activeSession) {
      this.currentSessionId = activeSession ? activeSession.session_id : null
    },
    setSessionId(sessionId) {
      this.currentSessionId = sessionId
    },
    async initCurrentSessionSetting() {
      try {
        if (!this.currentSessionId) {
          return
        }

        const { data } = await axios.get(`/api/sessions/${this.currentSessionId}`)

        this.fromPhoneNumber = data.session.fromPhoneNumber
        this.callInParallel = data.session.parallelCalls
        this.sessionName = data.session.sessionName
        const voicemailStore = useVoicemailStore()
        voicemailStore.setVoicemail(data.session.voicemail)
      } catch (error) {
        console.error('error initializing session settings', error)
      }
    },
    async initSessionSetting(sessionId) {
      try {
        const { data } = await axios.get(`/api/sessions/${sessionId}`)

        if (!this.sessions[sessionId]) {
          this.sessions[sessionId] = {}
        }

        this.sessions[sessionId].fromPhoneNumber = data.session.fromPhoneNumber
        this.sessions[sessionId].callInParallel = data.session.parallelCalls
        this.sessions[sessionId].sessionName = data.session.sessionName
        this.sessions[sessionId].voicemail = data.session.voicemail
      } catch (error) {
        console.error('error initializing session settings', error)
      }
    },
    async updateSessionSettings({ sessionName, fromPhoneNumberId, audioMessageId, parallelCalls }) {
      try {
        await axios.put(`/api/sessions/${this.currentSessionId}/settings`, {
          sessionName,
          fromPhoneNumberId,
          audioMessageId,
          parallelCalls
        })
        if (fromPhoneNumberId) {
          const phoneStore = usePhoneStore()
          phoneStore.computeNumberStatuses()
        }
      } catch (error) {
        console.error('error updating session settings', error)
      }
    },
    async deleteContact(sessionId, contact) {
      const index = this.sessions[sessionId].prospects.findIndex((item) => item.id === contact.id)
      try {
        this.sessions[sessionId].prospects.splice(index, 1)

        const { data } = await axios.delete(`/api/sessions/${sessionId}/prospects/${contact.id}`)
        console.log('Contact successfully deleted', data)
      } catch (error) {
        console.error('Error deleting contact:', error)
        this.sessions[sessionId].prospects.splice(index, 0, contact)
        this.errorMessage = error?.response?.data?.error || 'Could not delete the contact'
      }
    },
    async endSession() {
      if (!this.currentSessionId) {
        console.error('No active session to end')
        return
      }

      try {
        await axios.post(`/api/sessions/${this.currentSessionId}/end`)
        this.error = null
      } catch (error) {
        this.error = error.response?.data?.error || 'Failed to end session'
        console.error('Error ending session:', error)
      }
    },
    resetSession() {
      this.currentSessionId = null
      this.sessionName = ''
    },
    async updateField(sessionId, contact, { id, label }, value) {
      try {
        if (id === 'phoneNumber') {
          await updatePhoneNumberField(contact, this.phoneNumberFieldSelected.id, value)
          this.computeNumberStatuses(this.sessions[sessionId].prospects)
        } else {
          await updateField(contact, id, value)
        }
      } catch (error) {
        this.errorMessage = `Error updating prospect: ${label.toLowerCase()}`
      }
    },
    async openRowModal(sessionId, row) {
      this.selectedRowId = row.id

      this.fetchActivities(sessionId, row.id)
    },
    async fetchActivities(sessionId, contactId) {
      const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
      await fetchActivities(contact)
    },
    async fetchNotes(sessionId, contactId) {
      const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
      await fetchNotes(contact)
    },
    async fetchTasks(sessionId, contactId) {
      const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
      await fetchTasks(contact)
    },
    async updateTask(sessionId, contactId, taskId, taskField, taskValue) {
      try {
        const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
        await updateTask(contact, taskId, taskField, taskValue)
      } catch (error) {
        console.log('error updating task', error)
        this.errorMessage = `Error updating task`
      }
    },
    async toggleMeetingBooked(sessionId, contactId) {
      try {
        const currentValue = this.sessions[sessionId].prospects.find(
          (item) => item.id === contactId
        ).meetingBooked
        await axios.post(`/api/prospects/${contactId}/sessions/${sessionId}/meeting-booked`, {
          meetingBooked: !currentValue
        })
        console.log('Meeting booked status updated')

        this.sessions[sessionId].prospects.find((item) => item.id === contactId).meetingBooked =
          !this.sessions[sessionId].prospects.find((item) => item.id === contactId).meetingBooked
      } catch (error) {
        console.error('Error updating meeting booked status:', error)
        // Handle error (e.g., show error message)
      }
    },
    async fetchCallLogs(sessionId, contactId) {
      const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
      await fetchCallLogs(contact)
    },
    async updateLog(sessionId, contactId, logId, body) {
      try {
        const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
        await updateLog(contact, logId, body)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating log`
      }
    },
    async updateCallDisposition(sessionId, contactId, logId, callDisposition) {
      try {
        const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
        await updateCallDisposition(contact, logId, callDisposition)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating call disposition`
      }
    },
    async saveNewTask(contactId, task) {
      try {
        await saveNewTask(contactId, task)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error creating task`
      }
    },
    async updateNote(sessionId, contactId, noteId, body) {
      try {
        const contact = this.sessions[sessionId].prospects.find((item) => item.id === contactId)
        await updateNote(contact, noteId, body)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating note`
      }
    },
    async saveNewNote(contactId, body) {
      try {
        await saveNewNote(contactId, body)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error creating note`
      }
    },
    async fetchSessionProspectList(sessionId) {
      if (!sessionId) {
        console.error('No session ID')
        return
      }

      try {
        const { data } = await axios.get(`/api/sessions/${sessionId}/prospects`)

        if (!this.sessions[sessionId]) {
          this.sessions[sessionId] = {}
        }

        this.sessions[sessionId].headers = [
          { id: 'dialStatus', label: 'Dial status', sort: 'DESCENDING' },
          { id: 'firstName', label: 'First name', sort: 'DESCENDING', hideInModal: true },
          { id: 'lastName', label: 'Last name', sort: 'DESCENDING', hideInModal: true },
          { id: 'title', label: 'Title', sort: 'DESCENDING', hideInModal: true },
          {
            id: 'company',
            label: 'Company',
            sort: 'DESCENDING',
            hideInModal: true,
            nonEditable: data.prospects[0]?.importSource === 'hubspot'
          },
          {
            id: 'taskName',
            label: 'Task',
            sort: 'DESCENDING',
            hidden: !data.prospects[0].taskName,
            nonEditable: true
          },
          { id: 'email', label: 'Email', sort: 'DESCENDING', hideInTable: true },
          { id: 'status', label: 'Status', sort: 'DESCENDING', nonEditable: true },
          { id: 'phoneNumber', label: 'Phone number', sort: 'DESCENDING' },
          {
            id: 'dealName',
            label: 'Deal',
            sort: 'DESCENDING',
            nonEditable: data.prospects[0]?.importSource === 'hubspot',
            hideInTable: true,
            hidden: data.prospects[0]?.importSource === 'salesforce'
          },
          {
            id: 'dealUrl',
            label: 'Deal url',
            sort: 'DESCENDING',
            hidden: true
          },
          {
            id: 'companyUrl',
            label: 'Company url',
            sort: 'DESCENDING',
            hidden: true
          },
          ...data.customFields.map((column) => ({
            ...column,
            sort: 'DESCENDING',
            nonEditable: true
          }))
        ]

        // You can process the data here as needed before saving it to state
        this.sessions[sessionId].prospects = data.prospects
        this.computeNumberStatuses(data.prospects)
      } catch (error) {
        console.error('Error fetching session prospects:', error)
        this.error = error
      }
    },
    computeNumberStatuses(contacts) {
      for (const contact of contacts) {
        if (!contact.phoneNumber) {
          contact.errorStatus = 'no number'
          contact.wrongNumber = true
          continue
        }
        try {
          assertIsPhoneNumberValid(contact.phoneNumber)
        } catch (error) {
          contact.errorStatus = 'invalid number'
          contact.wrongNumber = true
          continue
        }

        try {
          const phoneStore = usePhoneStore()
          assertIsPhoneNumberAuthorized(contact.phoneNumber, phoneStore.authorizedCountryCodes)
        } catch (error) {
          contact.errorStatus = 'not-authorized'
          contact.wrongNumber = true
          continue
        }

        contact.errorStatus = ''
        contact.wrongNumber = false
      }
      const dialStatusOrder = ['country mismatch', 'not-authorized', 'invalid number', 'no number']

      contacts = contacts.sort((contactA, contactB) => {
        if (contactA.errorStatus && contactB.errorStatus) {
          const orderA = dialStatusOrder.indexOf(contactA.errorStatus)
          const orderB = dialStatusOrder.indexOf(contactB.errorStatus)
          return orderA - orderB
        }
        if (contactA.errorStatus) {
          return 1
        }
        if (contactB.errorStatus) {
          return -1
        }
        return 0
      })
    }
  }
})
