import { defineStore } from 'pinia'
import axios from '../services/axiosService'

export const useSalesforceStore = defineStore('salesforce', {
  state: () => ({
    isConnected: false
    // Add other Salesforce-specific states
  }),
  actions: {
    reset() {
      this.$reset()
    },
    async authenticateWithSalesforce() {
      try {
        const response = await axios.get('/api/salesforce/login-url')
        window.location.href = response.data.url
        // if (response.data.isAuthenticated) {
        //   this.isConnected = true
        //   // Handle other successful authentication logic
        // } else {
        //   this.isConnected = false
        // }
      } catch (error) {
        console.error('Salesforce authentication failed', error)
        this.isConnected = false
      }
    },
    async getAuthUrl() {
      try {
        const response = await axios.get('/api/salesforce/login-url')
        return response.data.url
      } catch (error) {
        console.error('Error fetching auth URL', error)
        return null
      }
    },
    setConnected(isConnected) {
      this.isConnected = isConnected
    }
    // Add other Salesforce-specific actions
  }
})
