<template>
  <div :class="{ container: activities.length === 0 }">
    <div class="loader-section" v-if="activities.length === 0 && loading">
      <Loader></Loader>
    </div>
    <div class="empty-state" v-else-if="activities.length === 0">There is no activity yet</div>
    <div
      v-for="pinnedActivity in pinnedActivities"
      :key="pinnedActivity.id"
      class="activity__section-body"
    >
      <div v-if="pinnedActivity?.type === 'note'">
        <prospect-note :note="pinnedActivity" @save-note="saveNote" />
      </div>
      <div v-if="pinnedActivity?.type === 'task'">
        <prospect-task
          :task="pinnedActivity"
          @updateTask="updateTask"
          :import-source="importSource"
        />
      </div>
      <div v-if="pinnedActivity?.type === 'call'">
        <prospect-call-log
          :call-log="pinnedActivity"
          @save-call-disposition="saveCallDisposition"
          @save-log="saveLog"
        />
      </div>
      <div v-if="pinnedActivity?.type === 'email'">
        <prospect-email :email="pinnedActivity" />
      </div>
    </div>
    <div v-for="activity in regularActivities" :key="activity.id" class="activity__section-body">
      <div v-if="activity.type === 'note'">
        <prospect-note :note="activity" @save-note="saveNote" />
      </div>
      <div v-if="activity.type === 'task'">
        <prospect-task :task="activity" @updateTask="updateTask" :import-source="importSource" />
      </div>
      <div v-if="activity.type === 'call'">
        <prospect-call-log
          :call-log="activity"
          @save-call-disposition="saveCallDisposition"
          @save-log="saveLog"
        />
      </div>
      <div v-if="activity.type === 'email'">
        <prospect-email :email="activity" />
      </div>
    </div>
  </div>
</template>

<script setup>
import Loader from './Loader.vue'
import ProspectNote from './ProspectNote.vue'
import ProspectEmail from './ProspectEmail.vue'
import ProspectTask from './ProspectTask.vue'
import ProspectCallLog from './ProspectCallLog.vue'
import { computed } from 'vue'

const props = defineProps({
  activities: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    required: true
  },
  importSource: {
    type: String,
    required: true
  }
})

const pinnedActivities = computed(() => props.activities.filter((activity) => activity.pinned))
const regularActivities = computed(() => props.activities.filter((activity) => !activity.pinned))

const emit = defineEmits(['updateTask', 'saveNote', 'saveLog', 'saveCallDisposition'])

function updateTask(taskId, taskField, taskValue) {
  emit('updateTask', taskId, taskField, taskValue)
}

function saveNote(id, note) {
  emit('saveNote', id, note)
}

function saveCallDisposition(logId, callDisposition) {
  emit('saveCallDisposition', logId, callDisposition)
}

function saveLog(logId, callBody) {
  emit('saveLog', logId, callBody)
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.loader-section {
  display: flex;
  justify-content: center;
}
.activity__section-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
</style>
