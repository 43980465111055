// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useSessionStore } from '@/stores/sessionStore'
import { usePhoneStore } from '@/stores/phone'
import Main from '../Main.vue'
import BackOffice from '../BackOffice.vue'
import BackOfficeUsers from '../BackOfficeUsers.vue'
import SessionListPage from '../SessionListPage.vue'
import SessionList from '../SessionList.vue'
import SessionDetails from '../SessionDetails.vue'
import AnalyticsPage from '../AnalyticsPage.vue'
import AnalyticsList from '../AnalyticsList.vue'
import AnalyticsDetails from '../AnalyticsDetails.vue'
import CallLogs from '../CallLogs.vue'
import AdminAnalytics from '../AdminAnalytics.vue'
import AppLayout from '../AppLayout.vue'
import Login from '../Login.vue'
import { useAnalyticsStore } from '@/stores/analyticsStore'

const routes = [
  {
    path: '/',
    component: AppLayout, // Use the layout component here
    children: [
      { name: 'main', path: '', component: Main, meta: { requiresAuth: true } },
      {
        name: 'back-office',
        path: 'back-office',
        component: BackOffice,
        meta: { requiresAuth: true }
      },
      {
        name: 'back-office-users',
        path: 'back-office/users',
        component: BackOfficeUsers,
        meta: { requiresAuth: true }
      },
      {
        name: 'session-list',
        path: 'sessions',
        component: SessionListPage,
        meta: { requiresAuth: true },
        children: [
          {
            name: 'session-details',
            path: ':sessionId',
            component: SessionDetails,
            meta: { requiresAuth: true },
            props: true
          },
          {
            name: 'session-list',
            path: '',
            component: SessionList,
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        name: 'analytics',
        path: 'analytics',
        component: AnalyticsPage,
        meta: { requiresAuth: true },
        children: [
          {
            name: 'analytics-details',
            path: 'user/:userId/:logType',
            component: AnalyticsDetails,
            meta: { requiresAuth: true },
            props: true
          },
          {
            name: 'analytics-list',
            path: '',
            component: AnalyticsList,
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        name: 'call-history',
        path: 'call-history',
        component: CallLogs,
        meta: { requiresAuth: true }
      },
      {
        name: 'admin-analytics',
        path: 'back-office/analytics',
        component: AdminAnalytics,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.isAuthenticated) {
        next({ name: 'main' }) // Redirect to the main app
      } else {
        next() // Continue to the login page
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  console.log('router beforeEach')
  const authStore = useAuthStore()

  if (!authStore.isInitialized) {
    await authStore.initializeAuth()
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !authStore.isAuthenticated) {
    // Redirect to the login page if not authenticated
    next({ name: 'login' })
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  const analyticsStore = useAnalyticsStore()

  if (from.name && from.name.includes('analytics') && !to.name.includes('analytics')) {
    analyticsStore.resetStore()
  }
  next()
})

const beforeRouteLeave = (to, from, next) => {
  const phoneStore = usePhoneStore()
  if (phoneStore.isDialingOrCalling) {
    if (!window.alert('Call in progress, cancel calls first')) {
      next(false)
    }
  } else {
    next()
  }
}

router.beforeEach(beforeRouteLeave)

export default router
