<template>
  <div class="back-office__users">
    <div class="header-section">
      <div v-if="!creatingCompany">
        <Button
          label="Create Company"
          icon="pi pi-plus"
          @click="startCreatingCompany"
          style="margin-left: 8px"
        />
      </div>
      <div v-else style="display: flex; align-items: center">
        <InputText
          v-model="newCompanyName"
          placeholder="Enter company name"
          style="margin-right: 8px"
        />
        <Button label="Save" icon="pi pi-check" @click="createCompany" style="margin-right: 8px" />
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-secondary"
          @click="cancelCreatingCompany"
        />
      </div>
    </div>
    <Toast position="bottom-right" />
    <div class="back-office__user-table">
      <div class="prime-table">
        <DataTable
          :value="users"
          style="width: 100%"
          scrollable
          scrollHeight="flex"
          tableStyle="padding-bottom: 12px"
        >
          <Column
            headerClass="data-table-header"
            bodyClass="cell"
            field="name"
            header="Name"
          ></Column>
          <Column
            field="company_name"
            header="Company"
            headerClass="data-table-header"
            bodyClass="cell"
          >
            <template #body="bodyProps">
              <div style="display: flex; align-items: center">
                <span v-if="!bodyProps.data.editingCompany">{{ bodyProps.data.company_name }}</span>
                <Dropdown
                  v-else
                  :options="companies"
                  v-model="bodyProps.data.newCompany"
                  optionLabel="name"
                  style="width: 150px"
                />
                <div
                  class="edit-icon-section"
                  v-if="!bodyProps.data.editingCompany"
                  @click="startEditingCompany(bodyProps.data)"
                >
                  <EditIcon class="edit-icon" size="20" />
                </div>
                <Button
                  label="Save"
                  icon="pi pi-save"
                  v-if="bodyProps.data.editingCompany"
                  @click="saveCompany(bodyProps.data)"
                  style="margin-left: 8px"
                />
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  v-if="bodyProps.data.editingCompany"
                  @click="cancelEditingCompany(bodyProps.data)"
                  style="margin-left: 8px"
                  severity="secondary"
                />
              </div>
            </template>
          </Column>
          <Column
            field="email"
            header="Email"
            headerClass="data-table-header"
            bodyClass="cell"
          ></Column>
          <Column
            field="phone_numbers"
            headerClass="data-table-header"
            bodyClass="cell"
            header="Phone Numbers"
          >
            <template #body="bodyProps">
              <div
                v-for="phone in bodyProps.data.phone_numbers"
                :key="phone.phone_number_id"
                class="phone-number"
              >
                <span>{{ phone.phone_number }} ({{ phone.source }})</span>
                <div
                  class="trash-icon-container"
                  @click="confirmDeletePhoneNumber(phone.phone_number_id)"
                >
                  <TrashIcon class="trash-icon" size="20" />
                </div>
              </div>
            </template>
          </Column>
          <Column header="Add Phone Number" headerClass="data-table-header" bodyClass="cell">
            <template #body="slotProps">
              <div style="display: flex; align-items: flex-start">
                <Dropdown
                  v-model="slotProps.data.selectedSource"
                  :options="sources"
                  placeholder="Select Source"
                  style="margin-right: 8px"
                />
                <div style="display: flex; flex-direction: column">
                  <template v-if="slotProps.data.selectedSource === 'Vonage'">
                    <InputText
                      v-model="slotProps.data.newPhoneNumber"
                      placeholder="Add Phone Number"
                      style="margin-right: 8px"
                      :class="{
                        'p-invalid':
                          !isPhoneNumberValid(slotProps.data.newPhoneNumber) &&
                          slotProps.data.newPhoneNumber
                      }"
                      @input="updateValidity(slotProps.data)"
                    />
                    <small
                      v-if="
                        !isPhoneNumberValid(slotProps.data.newPhoneNumber) &&
                        slotProps.data.newPhoneNumber
                      "
                      class="p-error"
                    >
                      Invalid phone number
                    </small>
                  </template>

                  <template v-else-if="slotProps.data.selectedSource === 'Twilio'">
                    <Dropdown
                      v-model="slotProps.data.newPhoneNumber"
                      :options="availableTwilioNumbers"
                      option-value="phone_number"
                      optionLabel="phone_number"
                      placeholder="Select Twilio Number"
                      style="margin-right: 8px"
                    />
                  </template>
                </div>
                <Button
                  label="Add"
                  icon="pi pi-plus"
                  :disabled="!isPhoneNumberValid(slotProps.data.newPhoneNumber)"
                  @click="
                    addPhoneNumber(
                      slotProps.data.user_id,
                      slotProps.data.newPhoneNumber,
                      slotProps.data.selectedSource
                    )
                  "
                ></Button>
              </div>
            </template>
          </Column>
          <Column
            field="redirect_number"
            header="Redirect number"
            headerClass="data-table-header"
            bodyClass="cell"
          >
            <template #body="bodyProps">
              <div style="display: flex; align-items: start">
                <!-- Show redirect number if not editing -->
                <span v-if="!bodyProps.data.editingRedirectNumber">{{
                  bodyProps.data.redirect_number
                }}</span>

                <!-- Input field to edit redirect number -->
                <div v-else style="display: flex; flex-direction: column">
                  <InputText
                    v-model="bodyProps.data.newRedirectNumber"
                    style="width: 150px"
                    @input="updateRedirectValidity(bodyProps.data)"
                    :class="{
                      'p-invalid':
                        !isPhoneNumberValid(bodyProps.data.newRedirectNumber) &&
                        bodyProps.data.newRedirectNumber
                    }"
                  />
                  <small
                    v-if="
                      !isPhoneNumberValid(bodyProps.data.newRedirectNumber) &&
                      bodyProps.data.newRedirectNumber
                    "
                    class="p-error"
                  >
                    Invalid phone number
                  </small>
                </div>

                <!-- Edit icon, visible when not editing -->
                <div
                  class="edit-icon-section"
                  v-if="!bodyProps.data.editingRedirectNumber"
                  @click="startEditingRedirectNumber(bodyProps.data)"
                >
                  <EditIcon class="edit-icon" size="20" />
                </div>

                <!-- Save button, visible when editing -->
                <Button
                  label="Save"
                  :disabled="!isPhoneNumberValid(bodyProps.data.newRedirectNumber)"
                  icon="pi pi-save"
                  v-if="bodyProps.data.editingRedirectNumber"
                  @click="saveRedirectNumber(bodyProps.data)"
                  style="margin-left: 8px"
                />

                <!-- Cancel button, visible when editing -->
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  v-if="bodyProps.data.editingRedirectNumber"
                  @click="cancelEditingRedirectNumber(bodyProps.data)"
                  style="margin-left: 8px"
                  severity="secondary"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Toast from 'primevue/toast'
import axios from './services/axiosService'
import { assertIsPhoneNumberValid } from './helpers/phoneNumber'
import EditIcon from './components/icons/EditIcon.vue'
import TrashIcon from './components/icons/TrashIcon.vue'
import { useToast } from 'primevue/usetoast'
import ConfirmDialog from 'primevue/confirmdialog'
import { useConfirm } from 'primevue/useconfirm'

const confirm = useConfirm()

const toast = useToast()

const users = ref([])
const companies = ref([])
const creatingCompany = ref(false)
const newCompanyName = ref('')

const sources = ['Vonage', 'Twilio']

const confirmDeletePhoneNumber = (phoneNumberId) => {
  confirm.require({
    message: 'Are you sure you want to delete this phone number?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Delete'
    },
    accept: () => {
      deletePhoneNumber(phoneNumberId)
      toast.add({
        severity: 'info',
        summary: 'Confirmed',
        detail: 'Phone number deleted',
        life: 3000
      })
    }
  })
}

const availableTwilioNumbers = ref([])

onMounted(() => {
  fetchAvailableTwilioNumbers()
})

async function fetchAvailableTwilioNumbers() {
  try {
    const response = await axios.get('/api/back-office/twilio-numbers')
    availableTwilioNumbers.value = response.data
  } catch (error) {
    console.error('Failed to fetch available Twilio numbers:', error)
  }
}

const startCreatingCompany = () => {
  creatingCompany.value = true
}

const cancelCreatingCompany = () => {
  creatingCompany.value = false
  newCompanyName.value = ''
}

const createCompany = async () => {
  try {
    await axios.post('/api/back-office/companies', {
      name: newCompanyName.value
    })
    creatingCompany.value = false
    newCompanyName.value = ''
    toast.add({ severity: 'success', summary: 'Success', detail: 'Company created', life: 3000 })
    fetchCompanies()
  } catch (error) {
    console.error('Error creating company:', error)
  }
}

const fetchUsers = async () => {
  try {
    const response = await axios.get('/api/back-office/users')
    users.value = response.data
  } catch (error) {
    console.error('Error fetching users:', error)
  }
}

const fetchCompanies = async () => {
  try {
    const response = await axios.get('/api/back-office/companies')
    companies.value = response.data
  } catch (error) {
    console.error('Error fetching companies:', error)
  }
}

const startEditingCompany = (user) => {
  user.editingCompany = true
  user.newCompany = companies.value.find((company) => company.company_id === user.company_id)
}

const startEditingRedirectNumber = (user) => {
  user.editingRedirectNumber = true
  user.newRedirectNumber = user.redirect_number
}

const saveRedirectNumber = async (user) => {
  try {
    await axios.post(`/api/back-office/users/${user.user_id}/redirect-number`, {
      // user_id: user.user_id,
      redirect_number: user.newRedirectNumber
    })

    user.redirect_number = user.newRedirectNumber // Save new redirect number
    user.editingRedirectNumber = false // Exit editing mode

    fetchUsers() // Refresh the users data
  } catch (error) {
    console.error('Error editing phone number redirect:', error)
  }
}

const cancelEditingRedirectNumber = (user) => {
  user.editingRedirectNumber = false // Exit editing mode
  user.newRedirectNumber = '' // Clear new redirect number
}

const saveCompany = async (user) => {
  try {
    await axios.get(
      `/api/back-office/update-company-and-fetch-modjo-user/user/${user.user_id}/company/${user.newCompany.company_id}`
    )
    user.company_name = user.newCompany.name
    user.company_id = user.newCompany.company_id
    user.editingCompany = false
  } catch (error) {
    console.error('Error saving company:', error)
  }
}

const cancelEditingCompany = (user) => {
  user.editingCompany = false
}

const isPhoneNumberValid = (phoneNumber) => {
  try {
    assertIsPhoneNumberValid(phoneNumber)
    return true
  } catch {
    return false
  }
}

const updateValidity = (user) => {
  user.isValidPhoneNumber = isPhoneNumberValid(user.newPhoneNumber)
}

const updateRedirectValidity = (user) => {
  user.isValidRedirectPhoneNumber = isPhoneNumberValid(user.newRedirectNumber)
}

const addPhoneNumber = async (userId, phoneNumber, source) => {
  try {
    console.log({ userId, phoneNumber })
    await axios.post('/api/back-office/phone-numbers', {
      user_id: userId,
      phone_number: phoneNumber,
      source
    })
    users.value.find((user) => user.user_id === userId).newPhoneNumber = ''
    fetchUsers() // Refresh the users data
    fetchAvailableTwilioNumbers()
  } catch (error) {
    console.error('Error adding phone number:', error)
  }
}

async function deletePhoneNumber(phone_number_id) {
  try {
    await axios.put(`/api/back-office/phone-numbers/${phone_number_id}`)
    fetchUsers()
    fetchAvailableTwilioNumbers()
  } catch (error) {
    console.error('Error deleting phone number:', error)
    alert('Failed to delete phone number')
  }
}

onMounted(() => {
  fetchUsers()
  fetchCompanies()
})
</script>

<style lang="scss">
.back-office__users {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1 1 auto;

  .back-office__user-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    align-items: start;

    border: 1px solid #eaecf0;
    border-radius: 8px;

    min-height: 0;
    flex: 1 1 auto;

    .prime-table {
      min-height: 0;
      flex: 1 1 auto;
      width: 100%;
      border-radius: 8px;
    }

    .p-datatable-table-container {
      border-radius: 8px;
    }
  }
  .p-component {
    font-size: 14px;
  }
  .edit-icon-section {
    border-radius: 4px;
    padding: 2px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    cursor: pointer;
    &:hover {
      cursor: pointer;
      background: rgba(55, 53, 47, 0.08);
      transition: background 0.02s ease;
    }
  }
  .edit-icon {
    stroke: #667085;
  }
  .header-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
  }
  .p-toast-message-text {
    margin: 0 0 0 0.5rem;
  }

  .phone-number {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    .trash-icon-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;
      margin-left: 8px;
      padding: 2px;
      &:hover {
        cursor: pointer;
        background: rgba(55, 53, 47, 0.08);
        transition: background 0.02s ease;
      }

      .trash-icon {
        stroke: #667085;
      }
    }
  }
}
</style>
