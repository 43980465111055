<template>
  <div class="prospect__note" :class="{ 'prospect__note--pinned': note.pinned }">
    <div class="note__header">
      <div class="note__title">
        {{ note.title }}
        <pinned-badge v-if="note.pinned" class="pinned-badge" />
      </div>
      <div class="note__date">{{ formatDate(note.date) }}</div>
    </div>
    <Textarea
      v-model="noteCopy.body"
      autoResize
      rows="2"
      cols="30"
      @blur="saveNote(note.id, noteCopy.body)"
      variant="filled"
    />
  </div>
</template>

<script setup>
import { formatDate } from '../helpers/date'
import PinnedBadge from './PinnedBadge.vue'
import Textarea from 'primevue/textarea'

import { ref, watch } from 'vue'

const props = defineProps({
  note: {
    type: Object,
    required: true
  }
})

const noteCopy = ref([])

noteCopy.value = JSON.parse(JSON.stringify(props.note))

watch(
  () => props.note,
  () => {
    noteCopy.value = JSON.parse(JSON.stringify(props.note))
  }
)

const emit = defineEmits(['saveNote'])

function saveNote(id, note) {
  if (props.note.body !== note) {
    emit('saveNote', id, note)
  }
}
</script>

<style scoped lang="scss">
.prospect__note {
  display: flex;
  flex-direction: column;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 16px;
  &--pinned {
    border: 1px solid #ababab;
  }
}
.note__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .note__title {
    font-size: 14px;
    font-weight: 500;
    display: flex;

    .pinned-badge {
      margin-left: 12px;
    }
  }
  .note__date {
    font-size: 12px;
    color: #667085;
  }
}
</style>
