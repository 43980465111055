<template>
  <div class="date-selector">
    <div class="date-selector-dropdown" ref="dateSelectorDropdown">
      <div @click="toggleDropdown" class="dropdown__button">
        <div class="dropdown__buttonText">{{ optionLabels[selectedOption] }}</div>
        <ChevronDownIcon class="dropdown__button-svg" v-if="!isOpen" color="#D0D5DD" size="12" />
        <ChevronUpIcon class="dropdown__button-svg" v-else color="#D0D5DD" size="12" />
      </div>
      <div v-if="isOpen" class="dropdown--open" @click.stop="">
        <div class="dropdown__main">
          <div
            class="dropdown__item"
            v-for="(label, value) in optionLabels"
            :key="value"
            @click="selectDateOption(value)"
          >
            <div
              class="dropdown__item-group"
              :class="{
                'dropdown__item--selected': value === selectedOption,
                'dropdown__item--unselected': value !== selectedOption
              }"
            >
              <div v-if="value === selectedOption" class="checkmark-icon">
                <CheckmarkIcon size="21" />
              </div>
              <div class="dropdown__item-text">
                {{ label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VueDatePicker
      v-if="selectedOption === 'custom'"
      class="date-picker"
      v-model="customDate"
      :enable-time-picker="false"
      :highlight-week-days="[0, 6]"
      @cleared="selectedOption = 'today'"
      :transitions="false"
      input-class-name="dp-custom-input"
      menu-class-name="dp-custom-menu"
    ></VueDatePicker>
    <VueDatePicker
      v-if="showTimePicker"
      v-model="selectedTime"
      time-picker
      class="time-picker"
      :clearable="false"
      :transitions="false"
      input-class-name="dp-custom-input"
      menu-class-name="dp-custom-menu dp-custom-time-picker"
      :preview-format="() => {}"
    >
      <template #input-icon>
        <clock-icon class="clock-icon" size="18" />
      </template>
    </VueDatePicker>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import { addDays, addBusinessDays, addWeeks, addMonths, format, isSameDay } from 'date-fns'
import ClockIcon from './icons/ClockIcon.vue'
import CheckmarkIcon from './icons/CheckmarkIcon.vue'
import ChevronDownIcon from './icons/ChevronDownIcon.vue'
import ChevronUpIcon from './icons/ChevronUpIcon.vue'
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  initialValue: {
    type: Date,
    default: () => new Date()
  },
  showTimePicker: {
    type: Boolean
  }
})

const isOpen = ref(false)

function toggleDropdown() {
  if (props.disable) {
    return
  }
  isOpen.value = !isOpen.value
}

const dateSelectorDropdown = ref(null)

function closeDropdown() {
  isOpen.value = false
}

onClickOutside(dateSelectorDropdown, () => {
  closeDropdown()
})

function selectDateOption(value) {
  selectedOption.value = value
  isOpen.value = false
}

const emit = defineEmits(['update:value'])

const selectedOption = ref('today')
const customDate = ref(props.initialValue)
const selectedTime = ref({
  hours: new Date().getHours(),
  minutes: new Date().getMinutes()
})

function determineInitialOptionAndTime() {
  const today = new Date()
  const initialValue = props.initialValue

  if (isSameDay(initialValue, today)) {
    selectedOption.value = 'today'
  } else if (isSameDay(initialValue, addDays(today, 1))) {
    selectedOption.value = 'tomorrow'
  } else if (isSameDay(initialValue, addBusinessDays(today, 2))) {
    selectedOption.value = '2-business-days'
  } else if (isSameDay(initialValue, addBusinessDays(today, 3))) {
    selectedOption.value = '3-business-days'
  } else if (isSameDay(initialValue, addWeeks(today, 1))) {
    selectedOption.value = '1-week'
  } else if (isSameDay(initialValue, addWeeks(today, 2))) {
    selectedOption.value = '2-weeks'
  } else if (isSameDay(initialValue, addMonths(today, 1))) {
    selectedOption.value = '1-month'
  } else if (isSameDay(initialValue, addMonths(today, 3))) {
    selectedOption.value = '3-month'
  } else if (isSameDay(initialValue, addMonths(today, 6))) {
    selectedOption.value = '6-month'
  } else {
    selectedOption.value = 'custom'
    customDate.value = initialValue
  }

  // Setting the time part based on the initialValue
  selectedTime.value.hours = initialValue.getHours()
  selectedTime.value.minutes = initialValue.getMinutes()
}

onMounted(() => {
  determineInitialOptionAndTime()

  watch([() => selectedOption.value, () => customDate.value, () => selectedTime.value], () => {
    emit('update:value', calculateDateTime())
  })
})

watch(
  () => selectedOption.value,
  (newValue) => {
    if (newValue === 'custom') {
      customDate.value = props.initialValue
    }
  }
)

const today = new Date()

const optionLabels = computed(() => {
  return {
    today: 'Today',
    tomorrow: 'Tomorrow',
    '2-business-days': `In 2 business days (${format(addBusinessDays(today, 2), 'EEEE')})`,
    '3-business-days': `In 3 business days (${format(addBusinessDays(today, 3), 'EEEE')})`,
    '1-week': `In 1 week (${format(addWeeks(today, 1), 'MMMM d')})`,
    '2-weeks': `In 2 weeks (${format(addWeeks(today, 2), 'MMMM d')})`,
    '1-month': `In 1 month (${format(addMonths(today, 1), 'MMMM d')})`,
    '3-month': `In 3 months (${format(addMonths(today, 3), 'MMMM d')})`,
    '6-month': `In 6 months (${format(addMonths(today, 6), 'MMMM d')})`,
    custom: 'Custom date'
  }
})

function calculateDateTime() {
  let date
  switch (selectedOption.value) {
    case 'tomorrow':
      date = addDays(new Date(), 1)
      break
    case '2-business-days':
      date = addBusinessDays(new Date(), 2)
      break
    case '3-business-days':
      date = addBusinessDays(new Date(), 3)
      break
    case '1-week':
      date = addWeeks(new Date(), 1)
      break
    case '2-weeks':
      date = addWeeks(new Date(), 2)
      break
    case '1-month':
      date = addMonths(new Date(), 1)
      break
    case '3-month':
      date = addMonths(new Date(), 3)
      break
    case '6-month':
      date = addMonths(new Date(), 6)
      break
    case 'custom':
      date = new Date(customDate.value)
      break
    default:
      date = new Date()
  }

  date.setHours(selectedTime.value.hours, selectedTime.value.minutes, 0)

  return date
}
</script>

<style lang="scss">
.date-selector {
  display: flex;
  user-select: none;
  .clock-icon {
    fill: #828282;
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
  .time-picker {
    width: 110px;
  }
  .date-picker {
    width: 160px;
    margin-right: 12px;
  }

  .date-selector-dropdown {
    margin-right: 12px;
    position: relative;
    display: inline-block;
    background: #fff;
    color: #344054;
  }
  .date-selector-dropdown .dropdown__button {
    padding: 6px 12px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: opacity 0.4s ease;
  }
  .dropdown__button:hover {
    border: 1px solid #c2c2c2;
    cursor: pointer;
  }
  .dropdown__buttonText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    margin-right: 8px;
  }

  .dropdown--open {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #344054;
    font-size: 14px;
    display: inline-block;
    font-weight: 500;
    overflow: visible;
    background: #fff;
    z-index: 2;
    min-width: 100%;
  }

  .dropdown__main {
    padding: 4px;
  }

  .dropdown__item-group {
    display: flex;
    align-items: center;
  }
  .dropdown__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.05s ease;
    padding: 4px 4px 4px 0;
  }
  .dropdown__item-text {
    /* max-width: 155px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown__item:hover {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.07);
    opacity: 1;
    cursor: pointer;
  }
  .checkmark-icon {
    display: flex;
  }
  .dropdown__item--selected {
    padding-left: 0;
    font-weight: 600;
  }
  .dropdown__item--unselected {
    padding-left: 21px;
  }
  .dropdown__button-svg {
    pointer-events: none;
  }

  .dp__theme_light {
    --dp-icon-color: #828282;
  }

  .dp-custom-input {
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    padding: 6px 12px 6px 35px;
    --dp-font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    color: #344054;

    &:hover {
      border: 1px solid #c2c2c2;
    }
  }
  .dp-custom-menu {
    border-radius: 8px;
  }
  .dp-custom-time-picker {
    .dp__overlay {
      height: auto !important;
    }
  }
}
</style>
