<script setup>
import { useAuthStore } from '@/stores/auth'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from './services/axiosService'
import salesforceLogo from '@/assets/salesforce-logo.png'
import hubspotLogo from '@/assets/hubspot-logo.png'
import pipedriveLogo from '@/assets/pipedrive-logo.png'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()

async function fetchUserInfo() {
  try {
    const response = await axios.get('/api/user-info')
    return response.data.userId
  } catch (error) {
    console.error('Error fetching user info:', error)
    // Handle errors (e.g., redirect to login page)
  }
}

onMounted(async () => {
  if (route.query.success === 'true') {
    // Update the store to reflect authenticated state
    const userId = await fetchUserInfo()
    console.log('userId', userId)
    if (userId) {
      authStore.setAuthStatus(userId)
      router.push({ name: 'main' })
    } else {
      console.error('error getting the user id')
      // Handle the case where the user info couldn't be fetched
      // This might include redirecting to a login page or showing an error message
    }

    // Redirect to the main app or a protected route
    router.push({ name: 'main' })
  }
})

import { useSalesforceStore } from './stores/salesforceStore'
import { useHubspotStore } from './stores/hubspotStore'
import { usePipedriveStore } from './stores/pipedriveStore'

const salesforceStore = useSalesforceStore()
const hubspotStore = useHubspotStore()
const pipedriveStore = usePipedriveStore()

async function login() {
  const authUrl = await salesforceStore.getAuthUrl()

  if (authUrl) {
    window.location.href = authUrl // Perform the redirection
  } else {
    console.error('Failed to get Salesforce authorization URL')
    // Handle the error case
  }
}

async function hubspotLogin() {
  const authUrl = await hubspotStore.getAuthUrl()

  if (authUrl) {
    window.location.href = authUrl // Perform the redirection
  } else {
    console.error('Failed to get Hubspot authorization URL')
    // Handle the error case
  }
}

async function pipedriveLogin() {
  const authUrl = await pipedriveStore.getAuthUrl()

  if (authUrl) {
    window.location.href = authUrl // Perform the redirection
  } else {
    console.error('Failed to get Pipedrive authorization URL')
    // Handle the error case
  }
}
</script>

<template>
  <div class="login__page">
    <div class="login__section">
      <div class="login__welcome">Welcome!</div>
      <div class="login__button" @click="login">
        <img class="login__logo" :src="salesforceLogo" alt="salesforce logo" />
        <div></div>
        Sign in with Salesforce
      </div>
      <div class="login__button" @click="hubspotLogin">
        <img class="login__logo" :src="hubspotLogo" alt="hubspot logo" />
        <div></div>
        Sign in with Hubspot
      </div>
      <div class="login__button" @click="pipedriveLogin">
        <img class="login__logo" :src="pipedriveLogo" alt="pipedrive logo" />
        <div></div>
        Sign in with Pipedrive
      </div>
    </div>
  </div>
</template>

<style scoped>
.login__page {
}
.login__section {
  max-width: 280px;
  margin: auto;
}
.login__welcome {
  color: #37352f;
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  margin-top: 30vh;
}
.login__button {
  display: flex;
  text-align: center;
  align-items: center;
  padding: 15px 16px;
  border-radius: 6px;
  border: 2px solid #dbdbdb;
  background: #fff;
  color: #37352f;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}

.login__button:hover {
  cursor: pointer;
  border-color: #c2c2c2;
}

.login__logo {
  width: 40px;
  margin-right: 12px;
  object-fit: cover;
  height: 100%;
}
</style>
