<template>
  <div>
    <div v-if="newNoteEditorOpen === false" class="add-note__button-section">
      <div class="add-note__button" @click="newNoteEditorOpen = true">
        <div class="plus-icon">
          <PlusIcon color="#828282" size="20" />
        </div>
        <div class="add-note__button-text">Add a new note</div>
      </div>
    </div>
    <div v-else class="add-note__editor-section">
      <div class="header">New note</div>
      <textarea class="editor-item custom-input" v-model="newNoteText" rows="5"> </textarea>
      <div class="add-note__footer">
        <div class="add-note__save-button" @click="saveNewNote(newNoteText)">Save new note</div>
      </div>
    </div>
    <div class="note__section-body" v-if="pinnedNotes.length">
      <div v-for="note in pinnedNotes" :key="note.id" class="note__section-body">
        <prospect-note :note="note" @save-note="saveNote" />
      </div>
    </div>
    <div class="loader-section" v-if="loading">
      <Loader></Loader>
    </div>
    <div v-for="note in regularNotes" :key="note.id" class="note__section-body">
      <prospect-note :note="note" @save-note="saveNote" />
    </div>
  </div>
</template>

<script setup>
import PlusIcon from './icons/PlusIcon.vue'
import Loader from './Loader.vue'
import ProspectNote from './ProspectNote.vue'

import { computed, ref, watch } from 'vue'

const props = defineProps({
  notes: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    required: true
  }
})

const newNoteEditorOpen = ref(false)
const newNoteText = ref('')

const pinnedNotes = computed(() => props.notes.filter((note) => note.pinned))
const regularNotes = computed(() => props.notes.filter((note) => !note.pinned))

watch(
  () => props.notes,
  () => {
    resetNewNote()
  }
)

function resetNewNote() {
  newNoteEditorOpen.value = false
  newNoteText.value = ''
}

const emit = defineEmits(['saveNewNote', 'saveNote'])

function saveNewNote() {
  emit('saveNewNote', newNoteText.value)
  newNoteEditorOpen.value = false
  resetNewNote()
}

function saveNote(id, note) {
  emit('saveNote', id, note)
}
</script>

<style scoped lang="scss">
.loader-section {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.note__section-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .note__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    .note__title {
      font-size: 14px;
      font-weight: 500;
    }
    .note__date {
      font-size: 12px;
      color: #667085;
    }
  }
}

.add-note__button-section {
  display: flex;
  margin-bottom: 16px;
  .add-note__button {
    padding: 4px 16px 4px 8px;
    transition: 0.05s ease;
    display: flex;
    &:hover {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.07);
      opacity: 1;
      cursor: pointer;
    }

    .plus-icon {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }

    .add-note__button-text {
      color: #828282;
    }
  }
}

.add-note__editor-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .header {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 14px;
  }

  .editor-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .editor-item__title {
      font-size: 12px;
      font-weight: 500;
      color: #667085;
      margin-bottom: 4px;
    }
  }

  .add-note__footer {
    display: flex;
    align-items: center;
    .add-note__save-button {
      background-color: #0070ff;
      color: #fff;
      padding: 4px 8px;
      font-weight: 500;
      border-radius: 4px;
      font-size: 14px;
      display: flex;
      align-items: center;
      transition:
        background-color 0.4s ease,
        border-color 0.4s ease;

      align-self: flex-start;

      &:hover {
        cursor: pointer;
        background-color: #006fe6;
      }
    }
  }
}

.custom-input {
  line-height: 1.6;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;

  resize: vertical;

  font-size: 14px;
  color: #667085;
  background: rgba(242, 241, 238, 0.6);
  border: 1px solid rgba(15, 15, 15, 0.1);
  padding: 5px;
  border-radius: 4px;

  border: 1px solid #dbdbdb;
  font-weight: 500;

  &:focus {
    border: 1px solid #0070ff;
    outline: 2px solid #cbe2ff;
  }
}
</style>
