import { defineStore } from 'pinia'
import axios from '@/services/axiosService'
import { fetchNotes } from '@/services/fetchNotes'
import { fetchTasks } from '@/services/fetchTasks'
import { saveNewNote } from '@/services/saveNewNote'
import { updateField } from '@/services/updateField'
import { updateCallDisposition } from '@/services/updateCallDisposition'
import { updateNote } from '@/services/updateNote'
import { updateTask } from '@/services/updateTask'
import { saveNewTask } from '@/services/saveNewTask'
import { updateLog } from '@/services/updateLog'
import { usePhoneStore } from './phone'
import { fetchActivities } from '@/services/fetchActivities'
import { updatePhoneNumberField } from '@/services/updatePhoneNumberField'

export const useContactStore = defineStore('contactStore', {
  state: () => ({
    contacts: [],
    contactLoading: false,
    oneCallContact: null,
    initialContacts: [],
    searchLoading: false,
    searchContactOpened: false,
    leadStatuses: [],
    callDispositionList: {},
    phoneNumberFieldsSelected: {},
    contactOwners: [],
    taskTypeList: {},
    headers: [],
    contactModalLoading: false
  }),
  actions: {
    async fetchContactOwners() {
      try {
        const response = await axios.get('/api/prospects/owners')
        this.contactOwners = response.data.owners
      } catch (error) {
        console.error('error fetching contact owners:', error)
      }
    },
    openSearchContact() {
      this.searchContactOpened = true
    },
    async loadInitialContacts() {
      const contacts = await this.searchContacts()
      this.initialContacts = contacts
    },
    setCallDispositionList(callDispositionList) {
      this.callDispositionList = callDispositionList
    },
    async searchContacts(query) {
      try {
        this.searchLoading = true
        const response = await axios.get('/api/prospects/search', {
          params: { query }
        })

        return response.data.contacts
      } catch (error) {
        console.error('Error searching contacts:', error)
      } finally {
        this.searchLoading = false
      }
    },
    async fetchContact(contact) {
      try {
        const phoneStore = usePhoneStore()

        this.contactLoading = true

        const response = await axios.post(`/api/prospects/${contact.importSource}/${contact.id}`)

        this.phoneNumberFieldsSelected[contact.importSource] =
          response.data.phoneNumberFieldsSelected

        this.headers = phoneStore.buildHeaders(response.data.contact, [])
        this.oneCallContact = response.data.contact

        const phoneNumberFieldSelected = this.phoneNumberFieldsSelected[contact.importSource]
        this.oneCallContact.phoneNumber =
          this.oneCallContact.phoneNumbers[phoneNumberFieldSelected.id]
        this.headers.find((header) => header.id === 'phoneNumber').label =
          phoneNumberFieldSelected.label

        this.contactLoading = false
        this.searchContactOpened = false

        phoneStore.checkPhoneNumber(this.oneCallContact)

        await fetchActivities(this.oneCallContact)
      } catch (error) {
        console.error('Error loading contact:', error)
        this.contactLoading = false
        this.searchContactOpened = false
      }
    },
    async saveNewNote(_, body) {
      try {
        await saveNewNote(this.oneCallContact.id, body)
        await fetchNotes(this.oneCallContact)
      } catch (error) {
        console.error(error)
      }
    },
    async saveNewTask(_, task) {
      try {
        await saveNewTask(this.oneCallContact.id, task)
        await fetchTasks(this.oneCallContact)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error creating task`
      }
    },
    async updateTask(_, taskId, taskField, taskValue) {
      try {
        await updateTask(this.oneCallContact, taskId, taskField, taskValue)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating note`
      }
    },
    async updateField(_, { id, label }, value) {
      try {
        if (id === 'phoneNumber') {
          const phoneNumberFieldSelected =
            this.phoneNumberFieldsSelected[this.oneCallContact.importSource]
          await updatePhoneNumberField(this.oneCallContact, phoneNumberFieldSelected.id, value)
        } else {
          await updateField(this.oneCallContact, id, value)
        }
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating prospect: ${label.toLowerCase()}`
      }
    },
    async updateNote(_, noteId, body) {
      try {
        await updateNote(this.oneCallContact, noteId, body)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating note`
      }
    },
    async updateLog(_, logId, body) {
      try {
        await updateLog(this.oneCallContact, logId, body)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating log`
      }
    },
    async updateCallDisposition(_, logId, callDisposition) {
      try {
        await updateCallDisposition(this.oneCallContact, logId, callDisposition)
      } catch (error) {
        console.error(error)
        this.errorMessage = `Error updating call disposition`
      }
    },
    resetOneContact() {
      this.oneCallContact = null
    }
  }
})
